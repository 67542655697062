import React from "react";
import { useTranslation } from "react-i18next";
import StyledUnsavedChangesLabel from "./styledUnsavedChangesLabel";

function UnsavedChangesLabel() {
  const { t } = useTranslation();
  return (
    <StyledUnsavedChangesLabel variant="body2" className="unsaved-label">
      {t("unsavedChanges")}
    </StyledUnsavedChangesLabel>
  );
}

export default UnsavedChangesLabel;
