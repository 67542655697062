const scrollToError = errors => {
  const firstErrorKey = Object.keys(errors)[0];

  let error = null;
  if (typeof errors[firstErrorKey] === "object") {
    const firstRow = Object.keys(errors[firstErrorKey])[0];
    error = document.querySelector(`[data-error-name="${firstRow}"]`);
  } else {
    error = document.querySelector(`[data-error-name="${firstErrorKey}"]`);
  }

  if (error) {
    error.scrollIntoView(false, {
      behavior: "smooth",
    });
  }
};

export default scrollToError;
