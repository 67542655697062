import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import { OrigoHeader } from "@oriola-origo/origo-app-shell";
import NotificationPopups from "../notificationPopups/notificationPopups";
import Dialog from "../dialog/dialog";
import NavigationPane from "../navigationPane/navigationPane";
import { isAllowed, Permission, ANY_CUSTOMER } from "../../auth";
import GenericPopup from "../../generic/popup/popup";

const useStyles = makeStyles(theme => ({
  container: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    "@media print": {
      backgroundColor: "white",
    },
  },
}));

export const APP_HEADER_HEIGHT_MOBILE = 58;
export const APP_HEADER_HEIGHT = 64;
export const NAVIGATION_PANE_WIDTH = 100;
const NAVIGATION_PANE_HEIGHT_MOBILE = 200;

const ensureNoTrailingSlash = url =>
  url.endsWith("/") ? url.slice(0, -1) : url;

let forceNavigate = false;
function Layout({ children, loadingPanel }) {
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:960px)");
  const { location } = history;
  const classes = useStyles();
  const { userData } = useSelector(state => state.user);
  const [allowedAddNewCase, setAllowedAddNewCase] = useState(false);
  const [unsavedChangesSettings, setUnsavedChangesSettings] = useState({
    showModal: false,
    lastLocation: location,
  });
  const { tokenData } = useSelector(state => state.user);
  useEffect(() => {
    setAllowedAddNewCase(
      isAllowed(userData, Permission.CASE_CREATE, ANY_CUSTOMER)
    );
  }, [userData]);

  const isFormModefied = () =>
    !!document.querySelectorAll(".unsaved-label").length;

  const handleBlockedNavigation = nextLocation => {
    if (!forceNavigate && isFormModefied()) {
      forceNavigate = false;
      setUnsavedChangesSettings({
        showModal: true,
        lastLocation: nextLocation,
      });
      return false;
    }
    forceNavigate = false;
    return true;
  };

  const handleConfirmNavigationClick = () => {
    forceNavigate = true;
    setUnsavedChangesSettings({ ...unsavedChangesSettings, showModal: false });
    history.push(unsavedChangesSettings.lastLocation.pathname);
  };
  const mainContentHeight = !isMobileScreen
    ? "100vh"
    : `calc(100vh - ${NAVIGATION_PANE_HEIGHT_MOBILE}px)`;

  const getAppHeaderHeight = () =>
    isMobileScreen ? APP_HEADER_HEIGHT_MOBILE : APP_HEADER_HEIGHT;

  const appContentHeight = `calc(100vh - ${getAppHeaderHeight()}px)`;
  return (
    <Box className={classes.container} height="100vh">
      <Prompt when message={handleBlockedNavigation} />
      <GenericPopup
        title="discard_changes"
        content="changes_not_saved"
        iconType="camera"
        show={unsavedChangesSettings.showModal}
        confirmTitle="continue_editing"
        cancelTitle="discard"
        onConfirm={() =>
          setUnsavedChangesSettings({
            ...unsavedChangesSettings,
            showModal: false,
          })
        }
        onCancel={handleConfirmNavigationClick}
        onClose={() =>
          setUnsavedChangesSettings({
            ...unsavedChangesSettings,
            showModal: false,
          })
        }
      />
      <Box style={{ zIndex: 1000, position: "relative" }}>
        <OrigoHeader
          origoAccessToken={tokenData.accessToken}
          origoUrl={ensureNoTrailingSlash(process.env.REACT_APP_ORIGO_URL)}
          activeApplicationId={process.env.REACT_APP_ORIGO_APP_ID}
          queryParameters={window.location.search}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={isMobileScreen ? "column" : "row"}
        minHeight={appContentHeight}
      >
        <Box order={isMobileScreen ? 2 : 1} zIndex={100}>
          <NavigationPane
            loadingPanel={loadingPanel}
            allowedAddNewCase={allowedAddNewCase}
            isMobileScreen={isMobileScreen}
          />
        </Box>

        <Box
          order={isMobileScreen ? 1 : 2}
          width="100%"
          height={mainContentHeight}
          flexGrow={1}
          py={4}
        >
          {children}
        </Box>
      </Box>
      <NotificationPopups />
      <Dialog />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loadingPanel: PropTypes.bool,
};

Layout.defaultProps = {
  loadingPanel: false,
};

export default Layout;
