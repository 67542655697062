import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  FileUpload,
  FileList,
  OriolaColors,
} from "@oriola-origo/origo-ui-core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
// eslint-disable-next-line import/no-cycle
import { updateCase } from "../../../../redux";
import { AddFile, Attachment } from "../../../../images";
import { FieldTitle } from "..";
import { ALLOWED_EXTENSIONS_LIST } from "../../../../constants/supportedFileFormats";
import {
  FieldErrors,
  validateAttachment,
  isInvalidFileNumbers,
  isInvalidFileLimitPerUserType,
  getAttachmentListNotYetUploaded,
  getValidationErrorMessage,
} from "../../../../utils/validations/validations";

function Attachments({
  disabled,
  existingAttachments,
  onAttachmentsUpload,
  id,
  showTitle,
  padding,
  flexDirection,
  attachmentError,
  setAttachmentError,
  reclamationCase,
  disableDeleteBtn,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { userData } = user;
  const { messages } = useSelector(state => state.message);

  const onUpdateCase = caseData => {
    dispatch(updateCase(caseData));
  };

  const onFilesSelected = files => {
    const newAttachments = (files || []).map(f => ({
      id: uuidv4(),
      origFileName: f.name,
      file: f,
      userName: userData.name,
      uploadedAt: moment().format("YYYY-MM-DD"),
      userId: userData.userId,
      validationError: validateAttachment(f, t),
    }));

    newAttachments.some(attachment => {
      if (attachment.validationError) {
        setAttachmentError(attachment.validationError);
      }
      return null;
    });

    const attachments = [...existingAttachments, ...newAttachments];

    const fileNumberError = isInvalidFileNumbers(
      getAttachmentListNotYetUploaded(attachments),
      t
    );
    const fileLimitError = isInvalidFileLimitPerUserType(
      userData,
      reclamationCase,
      messages,
      getAttachmentListNotYetUploaded(attachments),
      t
    );

    if (fileNumberError) {
      setAttachmentError(fileNumberError);
    }
    if (fileLimitError) {
      setAttachmentError(fileLimitError);
    }
    return onAttachmentsUpload
      ? onAttachmentsUpload(attachments)
      : onUpdateCase({ attachments });
  };

  const getAttachmentFileOpts = attachment => {
    const name = attachment.origFileName;
    const { userName } = attachment;
    const { uploadedAt } = attachment;
    const { userId } = attachment;
    const validationError = attachment.validationError?.message;

    // edit mode
    let highlightColor = null;
    let rightButtonText = null;
    // normal?
    if (attachment.file != null) {
      highlightColor = attachment.validationError
        ? "#FFE2E2"
        : OriolaColors.LightGreen;
      rightButtonText = t("remove");
    } else if (attachment.deleted === true) {
      highlightColor = OriolaColors.LightRed;
      rightButtonText = t("revert");
    } else {
      rightButtonText = t("remove");
    }

    if (disabled || (disableDeleteBtn && !attachment.file))
      rightButtonText = null;

    return {
      name,
      rightButtonText,
      highlightColor,
      userName,
      userId,
      uploadedAt,
      validationError,
    };
  };

  const handleAttachmentClick = (e, attachment) => {
    const attachments = [...existingAttachments];
    const idx = attachments.findIndex(f => f.id === attachment.id);
    if (idx >= 0) {
      const f = attachments[idx];
      if (f.file) {
        // new file not yet on server, just remove
        attachments.splice(idx, 1);

        const fileNumberError = isInvalidFileNumbers(
          getAttachmentListNotYetUploaded(attachments)
        );
        const fileLimitError = isInvalidFileLimitPerUserType(
          userData,
          reclamationCase,
          messages,
          getAttachmentListNotYetUploaded(attachments)
        );
        if (!fileNumberError && !fileLimitError) {
          const singleFileError = attachments.find(
            tmpAttachment => tmpAttachment.validationError
          );
          setAttachmentError(singleFileError || null);
        } else {
          setAttachmentError(
            fileNumberError && !fileLimitError
              ? fileNumberError
              : fileLimitError
          );
        }
      } else {
        // existing attachment, toggle deleted flag
        const currentlyDeleted = f.deleted || false;
        f.deleted = !currentlyDeleted;
      }
    }
    return onAttachmentsUpload
      ? onAttachmentsUpload(attachments)
      : onUpdateCase({ attachments });
  };

  const attachmentList = existingAttachments;

  return (
    <Box width="100%" mt={2} flexGrow={1} marginRight={1}>
      {showTitle && <FieldTitle title={t("attachments")} type="selection" />}
      <FileUpload
        py={padding}
        height="auto"
        disabled={disabled}
        id={id}
        onFilesSelected={files => onFilesSelected(files)}
        addFileImg={<AddFile style={{ height: "40px", marginRight: "10px" }} />}
        title={t("uploadFile")}
        flexDirection={flexDirection}
        description={`${t("or").toLowerCase()} ${t(
          "dragAndDrop"
        ).toLowerCase()}`}
        style={{ background: "transparent" }}
      />
      <Typography variant="body2" display="inline">
        {`${t("supportedFileFormats")} ${ALLOWED_EXTENSIONS_LIST.join(", ")}`}
      </Typography>
      {attachmentList.length !== 0 && (
        <FileList
          id="reclamationFileList"
          mt={1}
          files={attachmentList}
          getFileOpts={attachment => getAttachmentFileOpts(attachment)}
          onRightButtonClick={(e, attachment) =>
            !disabled && handleAttachmentClick(e, attachment)
          }
          attachmentImg={<Attachment />}
          emptyListText={t("noAttachments")}
        />
      )}
      {[
        FieldErrors.ATTACHMENT_TOO_MANY_FILES_AT_ONCE,
        FieldErrors.ATTACHMENT_OVER_LIMIT,
      ].includes(attachmentError?.type) && (
        <Typography variant="body2" display="inline" color="error">
          {`${getValidationErrorMessage(attachmentError?.type, t)}`}
        </Typography>
      )}
    </Box>
  );
}

Attachments.propTypes = {
  reclamationCase: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  disabled: PropTypes.bool,
  existingAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  onAttachmentsUpload: PropTypes.func,
  id: PropTypes.string,
  showTitle: PropTypes.bool,
  padding: PropTypes.number,
  flexDirection: PropTypes.string,
  attachmentError: PropTypes.shape({
    type: PropTypes.string,
  }),
  setAttachmentError: PropTypes.func,
  disableDeleteBtn: PropTypes.bool,
};

Attachments.defaultProps = {
  disabled: false,
  showTitle: true,
  padding: 2,
  flexDirection: "column",
  existingAttachments: [],
  attachmentError: null,
  setAttachmentError: null,
  onAttachmentsUpload: null,
  id: "reclamationFileUpload",
  reclamationCase: null,
  classes: {},
  disableDeleteBtn: false,
};

export default Attachments;
