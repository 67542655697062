import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Dialog from "@oriola-origo/core/lib/Dialog";
import DialogContent from "@oriola-origo/core/lib/DialogContent";
import DialogActions from "@oriola-origo/core/lib/DialogActions";
import DialogTitle from "@oriola-origo/core/lib/DialogTitle";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";
import RadioGroup from "@oriola-origo/core/lib/RadioGroup";
import TextField from "@oriola-origo/core/lib/TextField";
import { useTranslation } from "react-i18next";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../utils/validations/validations";
import { updateValidationError } from "../../../redux";

function ChangeQueueDialog({
  department,
  setDepartment,
  internalMessage,
  setInternalMessage,
  open,
  reclamationCase,
  handleCloseDialog,
  handleContinue,
  sourceQueue,
}) {
  const { t } = useTranslation();
  const { changingQueue } = useSelector(state => state.case);
  const dispatch = useDispatch();
  const { messageError } = useSelector(state => state.validationErrors);
  const { subQueue } = reclamationCase;
  const { queue } = department;

  const returnDepartmentSubQueues = Object.values(
    EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
  ).filter(
    returnsSubQueue =>
      returnsSubQueue !==
      EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
        .RETURN_DEPARTMENT_NO_SUBQUEUE
  );
  const csdSubQueues = Object.values(
    EventSubQueueTarget.CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES
  ).filter(
    returnsSubQueue =>
      returnsSubQueue !==
      EventSubQueueTarget.CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES.CSD_NO_SUBQUEUE
  );

  const sccSubQueues = Object.values(EventSubQueueTarget.SCC_SUBQUEUES).filter(
    returnsSubQueue =>
      returnsSubQueue !== EventSubQueueTarget.SCC_SUBQUEUES.SCC_NO_SUBQUEUE
  );

  const onValueChanged = e => {
    setDepartment({
      queue: e.target.value,
      subQueue: null,
    });
    dispatch(updateValidationError({ messageError: "" }));
  };

  const getSelectedQueue = () => {
    if (!queue) {
      return sourceQueue;
    }
    return queue;
  };

  const setSubDepartmentQueueValues = e => {
    setDepartment({
      queue: getSelectedQueue(),
      subQueue: e.target.value,
    });

    dispatch(updateValidationError({ messageError: "" }));
  };

  const handleInternalMessageChanged = e => {
    setInternalMessage(e.target.value);
  };

  const getSelectedSubQueue = () => {
    if (!queue && subQueue) {
      return subQueue;
    }
    return department.subQueue;
  };

  const onCloseDialog = (event, reason) => {
    // event The event source of the callback.
    // reason Can be: "escapeKeyDown", "backdropClick".
    if (reason !== "backdropClick") {
      handleCloseDialog();
    }
  };

  const handleChange = () => {
    if (!queue && !department.subQueue) {
      dispatch(
        updateValidationError({ messageError: FieldErrors.QUEUE_MANDATORY })
      );
      return;
    }
    if (changingQueue !== true) {
      department.subQueue
        ? handleContinue(queue, internalMessage, department.subQueue)
        : handleContinue(queue, internalMessage);
    }
  };

  const getReturnDepartmentSubQueues = () =>
    returnDepartmentSubQueues.map(target => ({
      value: target,
      label: t(`subQueues.${target}`),
    }));

  const getCSDSubQueues = () =>
    csdSubQueues.map(target => ({
      value: target,
      label: t(`subQueues.${target}`),
    }));

  const getSCCSubQueues = () =>
    sccSubQueues.map(target => ({
      value: target,
      label: t(`subQueues.${target}`),
    }));

  const getOptions = () => {
    const allOptions = [
      {
        label: t("queues.INVOICING_DEPARTMENT"),
        value: EventQueueTarget.INVOICING_DEPARTMENT,
      },
      {
        label: t("queues.CUSTOMER_SERVICE_DEPARTMENT"),
        value: EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT,
      },
      {
        label: t("queues.SCC"),
        value: EventQueueTarget.SCC,
      },
      {
        label: t("queues.TRANSLATIONS"),
        value: EventQueueTarget.TRANSLATIONS,
      },
      {
        label: t("queues.TRANSPORTATION"),
        value: EventQueueTarget.TRANSPORTATION,
      },
      {
        label: t("queues.QUALITY_ASSURANCE"),
        value: EventQueueTarget.QUALITY_ASSURANCE,
      },
      {
        label: t("queues.RETURN_DEPARTMENT"),
        value: EventQueueTarget.RETURN_DEPARTMENT,
      },
    ];
    return allOptions.filter(option => {
      const isTranslationRequired =
        reclamationCase.translationsRequired ||
        option.value !== EventQueueTarget.TRANSLATIONS;
      return isTranslationRequired;
    });
  };

  const getSubQueueOptions = subqueue => {
    if (subqueue === EventQueueTarget.RETURN_DEPARTMENT) {
      return getReturnDepartmentSubQueues();
    }
    if (subqueue === EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT) {
      return getCSDSubQueues();
    }
    if (subqueue === EventQueueTarget.SCC) {
      return getSCCSubQueues();
    }
    return [];
  };

  const hasSubQueues = subqueue =>
    [
      EventQueueTarget.RETURN_DEPARTMENT,
      EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT,
      EventQueueTarget.SCC,
    ].includes(subqueue);

  const displaySubQueues = subqueue => {
    if (hasSubQueues(subqueue)) {
      return (
        <Box sx={theme => ({ paddingLeft: theme.spacing(4) })}>
          <RadioGroup
            formLabel="Sub-queue"
            value={getSelectedSubQueue()}
            onClick={setSubDepartmentQueueValues}
            helperText={getValidationErrorMessage(messageError, t)}
            items={getSubQueueOptions(subqueue)}
          />
        </Box>
      );
    }
    return null;
  };

  const getCurrentQueueName = () => {
    if (subQueue) {
      return t(`subQueues.${subQueue}`);
    }
    return t(`queues.${sourceQueue}`);
  };

  // sourceQueue = real current queue
  // subQueue = real current sub-queue (string | null | undefined)
  // department.queue = selected queue (string [also ""] | null | undefined)
  // department.subQueue = selected sub-queue (string [also ""] | null | undefined)

  const hasChanged = () => {
    if (!department.queue) {
      // Selection not changed
      return false;
    }
    if (department.queue !== sourceQueue) {
      // Main queue changed
      return true;
    }
    if ((department.subQueue || "") !== (subQueue || "")) {
      // Sub-queue changed
      return true;
    }
    return false;
  };

  return (
    <OriolaThemeProvider>
      <Dialog open={open} onClose={onCloseDialog} id="change-queue-dialog">
        <DialogTitle onCloseButtonClick={() => handleCloseDialog()}>
          <Box display="flex" sx={{ flexDirection: "column" }}>
            {t("changeQueue.title")}
            <Typography
              sx={theme => ({
                fontSize: "16px",
                paddingBottom: theme.spacing(1),
                color: theme.palette.text.secondary,
              })}
            >
              {t("changeQueue.currentQueue")}
              :&nbsp;
              {getCurrentQueueName()}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "500px", minHeight: "500px" }}>
          <Box display="flex" sx={{ flexDirection: "row" }}>
            <RadioGroup
              formLabel="Queue"
              value={getSelectedQueue()}
              onClick={onValueChanged}
              items={getOptions()}
            />
            {displaySubQueues(getSelectedQueue())}
          </Box>
          <Box
            sx={theme => ({
              paddingBottom: theme.spacing(2),
              paddingTop: theme.spacing(2),
            })}
          >
            <Box display="flex" sx={{ alignItems: "baseline" }}>
              <Typography noWrap variant="subtitle2">
                {t("changeQueue.internalMessage")}
              </Typography>
              <Typography noWrap>
                &nbsp;
                {t("changeQueue.optional")}
              </Typography>
            </Box>
            <TextField
              value={internalMessage}
              onChange={event => handleInternalMessageChanged(event)}
              id="internal-message-input"
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              variant="outlined"
              placeholder={t("message.placeholder")}
              margin="dense"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={changingQueue}
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            {t("cancel")}
          </Button>
          <Button
            disabled={changingQueue || !hasChanged()}
            isLoading={changingQueue}
            variant="contained"
            color="secondary"
            onClick={handleChange}
          >
            {t("transfer")}
          </Button>
        </DialogActions>
      </Dialog>
    </OriolaThemeProvider>
  );
}

ChangeQueueDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleContinue: PropTypes.func,
  sourceQueue: PropTypes.string,
  department: PropTypes.shape({
    queue: PropTypes.string,
    subQueue: PropTypes.string,
  }),
  setDepartment: PropTypes.func,
  internalMessage: PropTypes.string,
  setInternalMessage: PropTypes.func,
  reclamationCase: PropTypes.shape({
    subQueue: PropTypes.string,
    translationsRequired: PropTypes.bool,
  }),
};

ChangeQueueDialog.defaultProps = {
  open: false,
  handleCloseDialog: null,
  handleContinue: null,
  sourceQueue: "",
  department: {
    queue: "",
    subQueue: "",
  },
  setDepartment: null,
  internalMessage: "",
  setInternalMessage: null,
  reclamationCase: {
    subQueue: "",
    translationsRequired: false,
  },
};

export default ChangeQueueDialog;
