import Typography from "@oriola-origo/core/lib/Typography";
import { styled } from "@oriola-origo/core/lib/styles";

const StyledUnsavedChangesLabel = styled(Typography)(({ theme }) => ({
  display: "block",
  color: theme.palette.text.secondary,
  margin: theme.spacing(0.5, 0),
}));

export default StyledUnsavedChangesLabel;
