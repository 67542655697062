/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FileList } from "@oriola-origo/origo-ui-core";
import Title from "../title/title";
import { CaseType } from "../../../constants/caseType";
import { isOriolaUser, isPharmaceuticalCompany } from "../../auth/permission";
import { getAttachmentFileOpts } from "../../../utils/attachments/attachments";
import UserType from "../../../constants/userType";
import { hideDialog, showDialog } from "../../../redux";
import { DeleteIcon } from "../../../images";
import * as ModifyAttachments from "./modifyAttachments";
import handleOpenFile from "./handleOpenFile";

type IAttachments = {
  reclamationCase: {
    items: any;
    attachments: any;
    reclamationId: string;
    caseType: string;
  };
  canEdit: boolean;
};

const useStyles = makeStyles(() => ({
  list: {
    maxWidth: 1000,
  },
}));

export const getSPDAttachments = (reclamationCase: any) => {
  const conclusionAttachments = [];
  const productHandlingAttachments = [];
  const productHandlingInformationAttachments = [];

  const { items } = reclamationCase;
  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    const { handlingData } = item;
    const {
      conclusion,
      productHandlingInstructions,
      productHandlingInformation,
    } = handlingData || {};

    const itemConclusionAttachments = (conclusion || {}).attachments || [];
    conclusionAttachments.push(...itemConclusionAttachments);

    const itemCproductHandlingAttachments =
      (productHandlingInstructions || {}).attachments || [];
    productHandlingAttachments.push(...itemCproductHandlingAttachments);

    const itemProductHandlingInformationAttachments =
      productHandlingInformation?.attachments || [];
    productHandlingInformationAttachments.push(
      ...itemProductHandlingInformationAttachments
    );
  }

  return {
    conclusionAttachments,
    productHandlingAttachments,
    productHandlingInformationAttachments,
  };
};

export const getMessagesAttachments = (messages: any) =>
  messages.reduce((pre: any, current: any) => {
    const attachments = (current.attachments || []).map((attachment: any) => {
      attachment.creator = current.creator;
      return attachment;
    });

    return [...pre, ...attachments];
  }, []);

export const removeAttachments = (
  reclamationCase: any,
  messages: any,
  deleteAttachmentId: any,
  requireMoreInfoFromCustomer: any,
  t: any,
  dispatch: any
) => {
  /*
      We've 2 types of attachments:
      1. Attachments in case: it can be removed by updating the case
      2. Attachments in messages: it can be removed by updating the message
      
     */

  const isAttachmentInCase = reclamationCase.attachments.some(
    (attachment: any) => attachment.id === deleteAttachmentId
  );

  if (isAttachmentInCase) {
    ModifyAttachments.removeAttachmentFromCase(
      deleteAttachmentId,
      reclamationCase,
      requireMoreInfoFromCustomer,
      t,
      dispatch
    );
  } else {
    ModifyAttachments.removeAttachmentFromMessage(
      deleteAttachmentId,
      messages,
      reclamationCase,
      t,
      dispatch
    );
  }
};
export const renderDeletePopup = (
  reclamationCase: any,
  messages: any,
  deleteAttachmentId: any,
  requireMoreInfoFromCustomer: any,
  dispatch: any,
  t: any
) => {
  dispatch(
    showDialog({
      open: true,
      description: t("deleteAttachmentDescription"),
      icon: (
        <div>
          <DeleteIcon />
        </div>
      ),
      title: t("deleteAttachment"),
      cancelButtonText: t("confirmLeavinPage.cancel"),
      okButtonText: t("delete"),
      onCancel: () => dispatch(hideDialog()),
      onOk: () => {
        dispatch(hideDialog());
        deleteAttachmentId &&
          removeAttachments(
            reclamationCase,
            messages,
            deleteAttachmentId,
            requireMoreInfoFromCustomer,
            t,
            dispatch
          );
      },
    })
  );
};
function Attachments(props: IAttachments) {
  let deleteAttachmentId = "";
  const { reclamationCase, canEdit } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { userData } = useSelector((state: any) => state.user);
  const { messages } = useSelector((state: any) => state.message);
  const { requireMoreInfoFromCustomer } = useSelector(
    (state: any) => state.case
  );

  const dispatch = useDispatch();

  const renderFileList = (attachmentList = [], id = "reclamationFileList") => (
    <FileList
      className={classes.list}
      id={id}
      mt={1}
      files={attachmentList}
      getFileOpts={(attachment: any) =>
        getAttachmentFileOpts(
          attachment,
          () => handleOpenFile(reclamationCase, messages, attachment),
          t,
          canEdit
        )
      }
      onRightButtonClick={(e: any, attachment: any) => {
        deleteAttachmentId = attachment.id;
        canEdit
          ? renderDeletePopup(
              reclamationCase,
              messages,
              deleteAttachmentId,
              requireMoreInfoFromCustomer,
              dispatch,
              t
            )
          : handleOpenFile(reclamationCase, messages, attachment);
      }}
      attachmentImg={<OpenInNewIcon fontSize="small" />}
      emptyListText={t("noAttachments")}
    />
  );

  const renderAttachments = () => {
    const { caseType } = reclamationCase;
    const caseAttachmentList = reclamationCase.attachments || [];
    const spdAttachments = getSPDAttachments(reclamationCase);
    const {
      conclusionAttachments,
      productHandlingAttachments,
      productHandlingInformationAttachments,
    } = spdAttachments;
    const messagesAttachments = getMessagesAttachments(messages);

    const customerAttachmentList: any = [
      ...caseAttachmentList,
      ...messagesAttachments.filter(
        (attachment: any) => attachment.creator.type === UserType.CUSTOMER
      ),
    ];

    const oriolaAttachmentList: any = [
      ...messagesAttachments.filter(
        (attachment: any) => attachment.creator.type === UserType.ORIOLA
      ),
      ...productHandlingInformationAttachments,
    ];

    const showProductHandlingAttachments =
      isOriolaUser(userData) || isPharmaceuticalCompany(userData);
    const visiblePrincipalAttachmentList =
      showProductHandlingAttachments === false
        ? conclusionAttachments
        : productHandlingAttachments.concat(conclusionAttachments);

    const principalAttachmentList: any = [
      ...visiblePrincipalAttachmentList,
      ...messagesAttachments.filter(
        (attachment: any) => attachment.creator.type === UserType.PRINCIPAL
      ),
    ];

    return (
      <Box>
        <Box mb={1}>
          <Typography variant="body1">{t("customer")}</Typography>
        </Box>
        {renderFileList(customerAttachmentList, "reclamationFileList")}
        <Box mt={3} mb={1}>
          <Typography variant="body1">{t("oriola")}</Typography>
        </Box>
        {renderFileList(oriolaAttachmentList, "oriolaFileList")}
        {caseType === CaseType.SUSPECTED_PRODUCT_DEFECT && (
          <>
            <Box mt={3} mb={1}>
              <Typography variant="body1">
                {t("pharmaceuticalCompany")}
              </Typography>
            </Box>
            {renderFileList(principalAttachmentList, "pharmaceuticalFileList")}
          </>
        )}
      </Box>
    );
  };

  return (
    <div className={(classes as any).root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title title={t("attachments")} />
      </Box>
      {renderAttachments()}
    </div>
  );
}

export default Attachments;
